import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Plank 4×1:00`}</p>
    <p>{`Side Plank 4x:30/side`}</p>
    <p>{`SDHP’s 4×6\\@approx 70%`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`200M Run`}</p>
    <p>{`15-K2E’s`}</p>
    <p>{`5-Deadlifts (275/185)(RX+ 315/215)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      